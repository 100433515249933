import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { aboutHolder } from "./about.module.scss";

export default function AboutPage() {
  return (
    <article className={aboutHolder}>
      <h1>About</h1>
      <section>
        <p>
          Girls Girls Girls was founded by Samantha Lindo and Eliza Shaddad in 2011 to be a collective of
          female artists who put on events which empower women as performers and raise awareness for the UK
          charity Orchid Project. Over the last ten years Lindo and Shaddad have forged links with female
          artists across the UK, from varied disciplines and genres, to create a loyal and mutually supportive
          network of talented women who work together to better their art, and who stand in solidarity with
          females around the world who have been affected by Female Genital Cutting.
        </p>
        <figure>
          <StaticImage
            src="../images/Sam-and-Eliza-About-Page.png"
            alt="Samantha Lindo and Eliza Shaddad"
            width={700}
          />
          <figcaption>Photo by Matilda Hill-Jenkins for Oh Comely Magazine</figcaption>
        </figure>
        <p>A few of the amazing artists we’ve had involved include:</p>
        <ul className="previousArtists">
          <li>
            <a
              href="http://www.sabrinamahfouz.com/"
              title="More about Sabrina Mahfouz"
              rel="noreferrer"
              target="_blank"
            >
              Sabrina Mahfouz
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/ruthbarnesmusic?lang=en"
              title="More about Ruth Barnes"
              rel="noreferrer"
              target="_blank"
            >
              Ruth Barnes
            </a>
          </li>
          <li>
            <a
              href="https://www.dominomusic.com/artists/arlo-day"
              title="More about Alice Barlow"
              rel="noreferrer"
              target="_blank"
            >
              Alice Barlow
            </a>
          </li>
          <li>
            <a
              href="http://www.brydemusic.com/home"
              title="More about Sarah Howells (aka Bryde)"
              rel="noreferrer"
              target="_blank"
            >
              Sarah Howells (aka Bryde)
            </a>
          </li>
          <li>
            <a
              href="https://jaybernard.co.uk/home.html"
              title="More about Jay Bernard"
              rel="noreferrer"
              target="_blank"
            >
              Jay Bernard
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/esther_manito?lang=en"
              title="More about Esther Manito"
              rel="noreferrer"
              target="_blank"
            >
              Esther Manito
            </a>
          </li>
          <li>
            <a
              href="http://joelletaylor.co.uk/"
              title="More about Joelle Taylor"
              rel="noreferrer"
              target="_blank"
            >
              Joelle Taylor
            </a>
          </li>
          <li>
            <a href="http://bethrowley.com/" title="More about Beth Rowley" rel="noreferrer" target="_blank">
              Beth Rowley
            </a>
          </li>
          <li>
            <a
              href="https://open.spotify.com/artist/4KFH2C5Il5iK9qPJB78INi"
              title="More about The One Taste Choir"
              rel="noreferrer"
              target="_blank"
            >
              The One Taste Choir
            </a>
          </li>
          <li>
            <a
              href="http://www.charlotteferreiraupholstery.co.uk/"
              title="More about Charlotte Ferreira"
              rel="noreferrer"
              target="_blank"
            >
              Charlotte Ferreira
            </a>
          </li>
          <li>
            <a
              href="https://bridgetminamore.com/"
              title="More about Bridget Minamore"
              rel="noreferrer"
              target="_blank"
            >
              Bridget Minamore
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/zoeellenbryant?lang=en"
              title="More about Zoë Ellen Bryant"
              rel="noreferrer"
              target="_blank"
            >
              Zoë Ellen Bryant
            </a>
          </li>
          <li>
            <a
              href="https://www.sofiaboriosi.com/"
              title="More about Sofía Boriosi"
              rel="noreferrer"
              target="_blank"
            >
              Sofía Boriosi
            </a>
          </li>
          <li>
            <a
              href="http://www.rosaroberts.com/about"
              title="More about Rosa Roberts"
              rel="noreferrer"
              target="_blank"
            >
              Rosa Roberts
            </a>
          </li>
          <li>
            <a
              href="https://en.wikipedia.org/wiki/Sister_Fa"
              title="More about Sister Fa"
              rel="noreferrer"
              target="_blank"
            >
              Sister Fa
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/naomigoggin/?hl=en"
              title="More about Naomi Goggin"
              rel="noreferrer"
              target="_blank"
            >
              Naomi Goggin
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/uglyinahotway/?hl=en"
              title="More about Aisha Mirza"
              rel="noreferrer"
              target="_blank"
            >
              Aisha Mirza
            </a>
          </li>
        </ul>
      </section>
    </article>
  );
}
